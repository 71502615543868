import { CommonModule } from "@angular/common";
import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { DialogFooterComponent } from "../dialog-footer/dialog-footer.component";

@Component({
  selector: "app-confirm-dialog",
  standalone: true,
  imports: [CommonModule, DialogFooterComponent],
  templateUrl: "./confirm-dialog.component.html"
})
export class ConfirmDialogComponent implements OnInit {
  isSaving: boolean;
  loadingText: string;
  smallFont: boolean;
  textLeft: boolean;
  title: string | boolean;
  innerHtml: boolean;
  message: string;
  acknowledge: boolean;
  confirmButton: string;
  cancelButton: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private DialogRef: MatDialogRef<ConfirmDialogComponent>
  ) {}

  ngOnInit(): void {
    this.loadingText = this.data.loadingText;
    this.smallFont = this.data.smallFont;
    this.title = this.data.title;
    this.textLeft = this.data.textLeft;
    this.innerHtml = this.data.innerHtml;
    this.message = this.data.message;
    this.acknowledge = this.data.acknowledge;
    this.confirmButton = this.data.hideConfirmButton ? null : 'Confirm';
    this.cancelButton = this.data.cancelActionName || 'Cancel';
  }

  confirm(): void {
    this.isSaving = true;
    this.DialogRef.disableClose = true;
    this.data.onConfirm();
  }

  close(): void {
    this.DialogRef.close();
    if (this.data.onClose) {
      this.data.onClose();
    }
  }
}
