@if (title) {
  <h3>{{ title }}</h3>
}

@if (!innerHtml) {
  <p
    class="text-center my-4"
    [ngClass]="{
      'text-sm': smallFont,
      'text-xl': !smallFont,
      'text-center': !textLeft,
      'text-left': textLeft
    }">
    <span [innerHTML]="data.message"> </span>
  </p>
} @else {
  <div class="text-center my-4" [innerHTML]="message"></div>
}

<div>
  @if (acknowledge) {
    <div class="flex justify-end mt-4 text-blue-700 text-lg">
      <button type="submit" class="focus:outline-none pl-4 py-2 uppercase" (click)="close()">
        Ok
      </button>
    </div>
  } @else {
    <app-dialog-footer
      [isSaving]="isSaving"
      [loadingText]="loadingText"
      (cancelAction)="close()"
      (submitAction)="confirm()"
      [submitActionName]="confirmButton"
      [cancelActionName]="cancelButton"
      [valid]="true" />
  }

</div>
